html{
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: sans-serif;
}

#root{
  height: 100vh;
}

body{
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #f2f2f2;
}

@media print {
  .print-list-item {
    page-break-inside: avoid !important;
  }
  .print-empty-note {
    display: none !important;
  }
}